import { QUERY_KEYS } from '@/config/QueryKeys';
import { getEmployees } from '@badgermoleV2/basic';
import { useQuery } from '@tanstack/react-query';

type UseEmployeesProps = { businessLocationId: number; userRole: string };

export const useEmployees = ({ businessLocationId, userRole }: UseEmployeesProps) => {
  const { data = [], isFetching } = useQuery({
    queryKey: [QUERY_KEYS.basic.employees, businessLocationId, userRole],
    queryFn: async ({ signal }) => getEmployees(businessLocationId, userRole, { signal })
  });

  return { employees: data, isEmployeesFetching: isFetching };
};
