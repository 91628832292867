import { useSearchParams, type URLSearchParamsInit } from 'react-router-dom';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGESIZE = 10;

export const usePagination = () => {
  const [searchParams, setSearchParams] = useSearchParams([
    ['page', DEFAULT_PAGE.toString()],
    ['pageSize', DEFAULT_PAGESIZE.toString()]
  ]);
  const page = searchParams.has('page') ? +searchParams.get('page')! : 1;
  const pageSize = searchParams.has('pageSize') ? +searchParams.get('pageSize')! : 10;

  const setToFirstPage = () =>
    setSearchParams((prev) => {
      return { ...prev, page: DEFAULT_PAGE.toString(), pageSize: `${pageSize}` };
    });

  const setPage = (page: number) =>
    setSearchParams((prev) => {
      return { ...prev, page: `${Math.max(page, DEFAULT_PAGE)}`, pageSize: `${pageSize}` };
    });
  const setPageSize = (pageSize: number) =>
    setSearchParams((prev) => {
      return {
        ...prev,
        page: DEFAULT_PAGE.toString(),
        pageSize: `${Math.max(pageSize, DEFAULT_PAGESIZE)}`
      };
    });

  const setUrlParams = (params: URLSearchParamsInit) => setSearchParams(params);

  return {
    page,
    pageSize,
    setToFirstPage,
    setPage,
    setPageSize,
    setUrlParams,
    DEFAULT_PAGE,
    DEFAULT_PAGESIZE
  };
};
