import { QUERY_KEYS } from '@/config/QueryKeys';
import { getVehicleStates } from '@badgermoleV2/vehicles';
import { useQuery } from '@tanstack/react-query';

export const useVehicleStates = () => {
  const { data = [] } = useQuery({
    queryKey: [QUERY_KEYS.vehicle.states],
    queryFn: async ({ signal }) => await getVehicleStates({ signal }),
    staleTime: 1000 * 60 * 60 * 9
  });

  return { vehicleStates: data };
};
