import { QUERY_KEYS } from '@/config/QueryKeys';
import { getRepairTasks, type RepairTasksFilterBody } from '@badgermoleV2/workshop';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export const useRepairTasks = (filterBody: RepairTasksFilterBody) => {
  const { data, isFetching } = useQuery({
    queryKey: [
      QUERY_KEYS.workshop.repairTickets,
      filterBody.page,
      filterBody.pageSize,
      filterBody.workshopId,
      filterBody.onlyOpenTasks,
      filterBody.states,
      filterBody.vin,
      filterBody.licencePlate,
      filterBody.assignee,
      filterBody.problemIds
    ],
    queryFn: async ({ signal }) =>
      getRepairTasks(
        filterBody.page,
        filterBody.pageSize,
        filterBody.workshopId,
        filterBody.onlyOpenTasks,
        filterBody.states,
        filterBody.vin,
        filterBody.licencePlate,
        filterBody.assignee,
        filterBody.problemIds,
        { signal }
      ),
    placeholderData: keepPreviousData
  });

  return { repairTasks: data, isRepairTasksFetching: isFetching };
};
