import { MUTATION_KEYS } from '@/config/MutationKeys';
import { getorCreateOnstreetTour } from '@badgermoleV2/onstreet';
import { useMutation } from '@tanstack/react-query';

export const useOnStreetTourActions = () => {
  const { mutateAsync: getOrCreateTour, isPending: isGetOrCreateTourPending } = useMutation({
    mutationKey: [MUTATION_KEYS.onstreet.getOrCreate],
    mutationFn: async (workshopId: number) => await getorCreateOnstreetTour(workshopId)
  });

  return { getOrCreateTour, isGetOrCreateTourPending };
};
