import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type AddDescriptionDialogContentProps = {
  close: () => void;
  onConfirm: (text: string) => Promise<void>;
};

export const AddDesriptionDialogContent = ({
  close,
  onConfirm
}: AddDescriptionDialogContentProps) => {
  const { t } = useTranslation();
  const [text, setText] = useState<string | null>(null);

  return (
    <>
      <DialogTitle>
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant="h2" fontWeight="bold">
            {t('workshop:repair:modal:description:title')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            m: 1,
            flexDirection: 'column'
          }}
        >
          <FormControl>
            <TextField
              label={t('workshop:repair:modal:description:label')}
              fullWidth
              onChange={(e) => setText(e.target.value || null)}
              multiline
              rows={2}
            ></TextField>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button color="primary" variant="outlined" onClick={close}>
          {t('common:buttons.cancel')}
        </Button>
        <Button
          disabled={text === '' || text === null}
          color="secondary"
          variant="outlined"
          onClick={async () => {
            if (text === null) return;
            await onConfirm(text);
            close();
          }}
        >
          {t('workshop:repair:modal:description:confirm')}
        </Button>
      </DialogActions>
    </>
  );
};
