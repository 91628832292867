import { QUERY_KEYS } from '@/config/QueryKeys';
import { createOnStreetRepairTaskByLicencePlate } from '@badgermoleV2/onstreet';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useCreateAdhocRepairTask = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useMutation({
    mutationFn: async ({
      tourId,
      licencePlate,
      problemIds
    }: {
      tourId: number;
      licencePlate: string;
      problemIds: number[];
    }) => await createOnStreetRepairTaskByLicencePlate(tourId, licencePlate, problemIds),
    onSuccess: (data, { tourId }) => {
      queryClient.setQueryData([QUERY_KEYS.onstreet.getTour, tourId], data);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.onstreet.getTourOverview, tourId]
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.onstreet.getTasks]
      });
    }
  });

  return { createAdhocRepairTask: mutateAsync, isCreateAdhocRepairTaskPending: isPending };
};
