import { LastReportsDialog } from '@/components/Dialogs/LastReportsDialog';
import { usePersistedReports } from '@/hooks/Reports/usePersistedReports';
import { useVehicleReport } from '@/hooks/Reports/useVehicleReport';
import { useDebounce } from '@/hooks/Utility/useDebounce';
import { useTitle } from '@/hooks/Utility/useTitle';
import { useStore } from '@/store';
import type { ExtendedBase64Image } from '@/utils/ReportUtils';
import { ReportUtils } from '@/utils/ReportUtils';
import type { Vehicle, VehicleReportBodyIncidentsEnum } from '@badgermoleV2/api';
import { type GpsPosition } from '@badgermoleV2/api';
import { Search, Send } from '@mui/icons-material';
import type { SnackbarOrigin } from '@mui/material';
import {
  Alert,
  Box,
  Button,
  Fab,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CheckboxList } from './CheckboxList';
import { ImagePreview } from './ImagePreview';
import { VehicleSelection } from './VehicleSelection';

const SnackBarPosition: SnackbarOrigin = { vertical: 'top', horizontal: 'center' };

export const VehicleReportScreen = () => {
  useTitle({ title: 'report-vehicle:title' });
  const { t } = useTranslation();
  const [delay] = useStore((s) => [s.VEHICLE_REPORT_SEARCH_DELAY]);

  const [searchParams] = useSearchParams();

  const [openLastReports, setOpenLastReports] = useState(false);
  const [toManyImagesSelected, setToManyImagesSelected] = useState(false);

  const [licencePlate, setLicencePlate] = useState('');
  const [comment, setComment] = useState('');
  const [extendedB64Images, setExtendedB64images] = useState<ExtendedBase64Image[]>([]);
  const [incidentTypes, setIncidentTypes] = useState<VehicleReportBodyIncidentsEnum[]>([]);
  const { debouncedValue: licencePlateDebounced } = useDebounce(licencePlate, delay);
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [isVehicleUnknown, setIsVehicleUnknown] = useState(false);

  const { data: lastReports, refetch: fetchLastReports } = usePersistedReports(vehicle);
  const {
    vehicleList,
    isSending,
    disableSend,
    send,
    totalImageSizeReached,
    isLoadingVehicle,
    compressFileList,
    processingImages,
    location
  } = useVehicleReport(licencePlateDebounced, comment, extendedB64Images, incidentTypes);

  const resetReportData = () => {
    setLicencePlate('');
    setComment('');
    setExtendedB64images([]);
    setIncidentTypes([]);
    setVehicle(undefined);
    setIsVehicleUnknown(false);
  };

  useEffect(() => {
    if (vehicleList.length === 1) setVehicle(vehicleList[0]);
  }, [vehicleList]);

  return (
    <>
      <Fab
        disabled={disableSend || (!vehicle && !isVehicleUnknown)}
        sx={{ position: 'fixed', bottom: '1rem', right: '1rem' }}
        color="primary"
        data-testid="submit"
        onClick={async () => {
          send({
            description: comment,
            images: extendedB64Images.map((image) => image.image),
            incidents: incidentTypes,
            position: {
              lat: location?.coords.latitude,
              lon: location?.coords.longitude
            } as GpsPosition,
            vehicleIdentifier: {
              licencePlate: vehicle?.licencePlate,
              vehicleId: vehicle?.vehicleId,
              vin: vehicle?.vin
            },
            reportedDuringTourId: searchParams.has('reportedDuringTourId')
              ? parseInt(searchParams.get('reportedDuringTourId')!)
              : undefined
          });
          resetReportData();
        }}
      >
        <Send />
      </Fab>

      <Snackbar anchorOrigin={SnackBarPosition} open={isSending}>
        <Alert severity="info"> {t('report-vehicle:sendingReport')}</Alert>
      </Snackbar>

      <LastReportsDialog
        reports={lastReports ?? []}
        open={openLastReports}
        handleOk={() => setOpenLastReports(false)}
        vehicle={vehicle!}
      />

      <Stack spacing={1} sx={{ margin: '1rem' }}>
        {!(searchParams.get('disableSearch') === 'true') && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <form
              onSubmit={async (e) => {
                e.preventDefault();
              }}
            >
              <TextField
                fullWidth
                label={t('report-vehicle:licencePlate')}
                placeholder={t('report-vehicle:search.licencePlate')}
                value={licencePlate}
                disabled={isVehicleUnknown}
                onChange={(e) => {
                  setLicencePlate(e.target.value);
                  setVehicle(undefined);
                }}
                inputProps={{
                  'data-testid': 'search'
                }}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      data-testid="searchButton"
                      disabled={isVehicleUnknown || licencePlate.length < 3}
                      type="submit"
                    >
                      <Search />
                    </IconButton>
                  )
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    data-testid="identifyVehicleSwitch"
                    color="primary"
                    checked={isVehicleUnknown}
                    onChange={(e, checked) => {
                      setIsVehicleUnknown(checked);
                      setVehicle(undefined);
                    }}
                  />
                }
                label={t('report-vehicle:search.identify')}
              />
            </form>
          </Box>
        )}
        <Stack spacing={1}>
          {!isVehicleUnknown &&
            (isLoadingVehicle ? (
              <LinearProgress />
            ) : !vehicle ? (
              <VehicleSelection vehicleList={vehicleList} setVehicle={setVehicle} />
            ) : (
              <Box
                data-testid="searchResultList"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Typography data-testid="SelectedLicencePlate" fontWeight="bold">
                  {vehicle.licencePlate}
                </Typography>
                <Button
                  data-testid="lastReportsButton"
                  sx={{ marginLeft: '8px' }}
                  variant="outlined"
                  size="small"
                  onClick={async () => {
                    await fetchLastReports();
                    setOpenLastReports(true);
                  }}
                >
                  {t('common:buttons.lastReports')}
                </Button>
              </Box>
            ))}

          <TextField
            data-testid="reportDescription"
            label={t('report-vehicle:problemDescription')}
            value={comment}
            inputProps={{ maxLength: 1000 }}
            multiline
            fullWidth
            rows={4}
            variant="filled"
            onChange={(e) => setComment(e.target.value)}
          />

          <CheckboxList
            items={ReportUtils.incidents}
            selectedItems={incidentTypes}
            setItems={setIncidentTypes}
            translationLocation="report-vehicle:incidentType"
          />

          <ImagePreview
            images={extendedB64Images}
            onSingleImageRemove={(url: string) => {
              const images = extendedB64Images.filter((image) => image.identifier !== url);
              setExtendedB64images(images);
              setToManyImagesSelected(images.length > 12);
            }}
          />
          <Button
            disabled={processingImages}
            color="secondary"
            data-testid="addImageButton"
            variant="outlined"
            component="label"
          >
            {t('common:buttons.addImage')}
            <input
              hidden
              multiple
              accept="image/png,image/jpeg"
              id="choose-files"
              name="image"
              type="file"
              onChange={async (e) => {
                const images = await compressFileList(e.target.files!);
                if (images) {
                  setExtendedB64images((prev) => [...prev, ...images]);
                  return;
                }
                setToManyImagesSelected(true);
              }}
            />
          </Button>
          {(totalImageSizeReached || toManyImagesSelected) && (
            <Box
              data-testid="imagesLimit"
              color={'red'}
              fontWeight={'bold'}
              sx={{ marginTop: '1rem' }}
            >
              {t('report-vehicle:tooManyImages')}
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
};
