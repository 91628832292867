import { QUERY_KEYS } from '@/config/QueryKeys';
import { getRepairTaskLogs } from '@badgermoleV2/workshop';
import { useQuery } from '@tanstack/react-query';

export const useRepairTaskLogs = (repairTaskId: number) => {
  const { data = [], isFetching } = useQuery({
    queryKey: [QUERY_KEYS.repair.getLogs, repairTaskId],
    queryFn: async ({ signal }) => getRepairTaskLogs(repairTaskId, { signal })
  });

  return { repairLogs: data, isRepairLogsFetching: isFetching };
};
