import { QUERY_KEYS } from '@/config/QueryKeys';
import { getAllRepairTaskForOnstreetRepair } from '@badgermoleV2/onstreet';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

export const useAvailableOnstreetTasksAssetInfo = () => {
  const { data = [], isFetching } = useQuery({
    queryKey: [QUERY_KEYS.onstreet.getTasks],
    queryFn: async ({ signal }) => await getAllRepairTaskForOnstreetRepair({ signal }),
    placeholderData: keepPreviousData
  });

  return { onstreetTasksAssetInfo: data, isOnStreetTasksFetching: isFetching };
};
