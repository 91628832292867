import { URL_PARAMS } from '@/App/routes/routes';

export class TaskRoutingUtil {
  static replaceUrlParamsWithIds(ROUTE: string, taskId: number) {
    const slicedTaskParam = URL_PARAMS.TASK.replace('/', '');
    return ROUTE.replace(slicedTaskParam, taskId.toString()).replace(
      slicedTaskParam,
      taskId?.toString() ?? ''
    );
  }
}
