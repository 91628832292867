import { QUERY_KEYS } from '@/config/QueryKeys';
import { getTransitionsForRepairTask } from '@badgermoleV2/workshop';
import { useQuery } from '@tanstack/react-query';

export const useRepairTransitions = (taskId: number) => {
  const {
    data = [],
    isFetching,
    refetch
  } = useQuery({
    queryKey: [QUERY_KEYS.repair.getRepairTransitions, taskId],
    queryFn: async ({ signal }) => await getTransitionsForRepairTask(taskId, { signal })
  });

  return {
    repairTransitions: data,
    isRepairTransitionsFetching: isFetching,
    refetchRepairTransitions: refetch
  };
};
