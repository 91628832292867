import { MUTATION_KEYS } from '@/config/MutationKeys';
import { QUERY_KEYS } from '@/config/QueryKeys';
import { commentOnAsset, getCommentsOfAsset } from '@badgermoleV2/basic';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useAssetComments = (vin: string | undefined, taskId: number | undefined) => {
  const queryClient = useQueryClient();

  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.vehicle.assetComments, vin],
    queryFn: async ({ signal }) => await getCommentsOfAsset(vin!, { signal }),
    staleTime: 1000 * 60 * 5,
    enabled: vin !== undefined,
    placeholderData: keepPreviousData
  });

  const { mutateAsync: createAssetComment, isPending: isCreateAssetPending } = useMutation({
    mutationKey: [MUTATION_KEYS.vehicle.createAssetComment],
    mutationFn: async (message: string) => await commentOnAsset(vin!, message),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.vehicle.assetComments, vin] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.repair.getLogs, taskId] });
    }
  });

  return {
    assetComments: data,
    isAssetCommentsFetching: isFetching,
    createAssetComment,
    createAssetCommentPending: isCreateAssetPending
  };
};
