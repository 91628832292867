import { ROUTES } from '@/App/routes/routes';
import { QUERY_KEYS } from '@/config/QueryKeys';
import { useOnStreetTourActions } from '@/hooks/OnStreet/useOnStreetTourActions';
import { useWorkshops } from '@/hooks/Workshops/useWorkshops';
import { useStore } from '@/store';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const OnStreetPrepareScreen = () => {
  const setOngoingOnStreetTourId = useStore((s) => s.setOngoingOnStreetTourId);
  const [selectedWorkshopId, setSelectedWorkshopId] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const { workshops } = useWorkshops();
  const { getOrCreateTour, isGetOrCreateTourPending } = useOnStreetTourActions();

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const selectWorkshopWhenOnlyOneAvailable = useCallback(async () => {
    if (workshops.length === 1) {
      const t = await getOrCreateTour(workshops[0].id);
      setOngoingOnStreetTourId(t.id);
      queryClient.setQueryData([QUERY_KEYS.onstreet.getTour, t.id], t);
      navigate(ROUTES.WORKSHOP.ONSTREET);
    }
  }, [workshops, getOrCreateTour]);

  useEffect(() => {
    selectWorkshopWhenOnlyOneAvailable();
  }, [workshops, getOrCreateTour]);

  return (
    <Box>
      <Dialog open={open} onClose={() => setOpen(false)} keepMounted={false}>
        <DialogTitle>{t('workshop:onstreet.confirmWorkshop.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('workshop:onstreet.confirmWorkshop.description', {
              workshop: workshops.find((w) => w.id === selectedWorkshopId)?.name
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{t('common:buttons.cancel')}</Button>
          <Button
            onClick={async () => {
              if (selectedWorkshopId === null) {
                setOpen(false);
                return;
              }
              const t = await getOrCreateTour(selectedWorkshopId);
              setOngoingOnStreetTourId(t.id);
              queryClient.setQueryData([QUERY_KEYS.onstreet.getTour, t.id], t);
              navigate(ROUTES.WORKSHOP.ONSTREET);
            }}
          >
            {t('common:buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h6">{t('workshop:onstreet.confirmWorkshop.choose')}</Typography>
      <Stack gap={1}>
        {isGetOrCreateTourPending && <LinearProgress />}
        {workshops.map((workshop) => (
          <Paper
            key={`WorkshopPaper${workshop.id}`}
            onClick={() => {
              setSelectedWorkshopId(workshop.id);
              setOpen(true);
            }}
            sx={{ margin: 1, padding: 1, '&:hover': { backgroundColor: grey[200] } }}
          >
            <Typography fontSize="large" fontWeight="bold">
              {workshop.name}
            </Typography>
            <Typography>{workshop.physicalLocation.name}</Typography>
          </Paper>
        ))}
      </Stack>
    </Box>
  );
};
